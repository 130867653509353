.overflow-line-clamp {
  line-clamp: var(--lines, 2);
  box-orient: vertical;
  -webkit-line-clamp: var(--lines, 2);
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: clip;
  white-space: normal;
}

.for-screen-reader {
  block-size: 1px;
  clip-path: inset(50%);
  inline-size: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}
