:root {
  /* Named color values */
  --lch-black: 0% 0 0;
  --lch-white: 100% 0 0;
  --lch-gray-light: 96% 0.005 96;
  --lch-gray: 92% 0.005 96;
  --lch-gray-dark: 75% 0.005 96;
  --lch-blue: 54% 0.15 255;
  --lch-blue-light: 95% 0.03 255;
  --lch-blue-dark: 80% 0.08 255;
  --lch-orange: 70% 0.2 44;
  --lch-red: 51% 0.2 31;
  --lch-green: 65.59% 0.234 142.49;
  --lch-green-light: 95% 0.03 142.49;
  --lch-always-black: 0% 0 0;
  --lch-always-white: 100% 0 0;

  /* Abstractions */
  --color-negative: oklch(var(--lch-red));
  --color-positive: oklch(var(--lch-green));
  --color-positive-light: oklch(var(--lch-green-light));
  --color-bg: oklch(var(--lch-white));
  --color-ink: oklch(var(--lch-black));
  --color-ink-reversed: oklch(var(--lch-white));
  --color-link: oklch(var(--lch-blue));
  --color-subtle-light: oklch(var(--lch-gray-light));
  --color-subtle: oklch(var(--lch-gray));
  --color-subtle-dark: oklch(var(--lch-gray-dark));
  --color-selected: oklch(var(--lch-blue-light));
  --color-selected-dark: oklch(var(--lch-blue-dark));
  --color-marker: oklch(var(--lch-orange));
  --color-always-black: oklch(var(--lch-always-black));
  --color-always-white: oklch(var(--lch-always-white));

  /* Redefine named color values for dark mode */
  @media (prefers-color-scheme: dark) {
    --lch-black: 100% 0 0;
    --lch-white: 0% 0 0;
    --lch-gray-light: 25.2% 0 0;
    --lch-gray: 30.12% 0 0;
    --lch-gray-dark: 44.95% 0 0;
    --lch-blue: 72.25% 0.16 248;
    --lch-blue-light: 28.11% 0.053 248;
    --lch-blue-dark: 42.25% 0.07 248;
    --lch-red: 73.8% 0.184 29.18;
    --lch-green: 75% 0.21 141.89;
    --lch-green-light: 28.11% 0.02 142.49;
  }
}

.colorize--white {
  filter: invert(1);

  @media (prefers-color-scheme: dark) {
    filter: invert(0);
  }
}

.colorize--black {
  filter: invert(0);

  @media (prefers-color-scheme: dark) {
    filter: invert(1);
  }
}

.hyk-theme {
  background: #41295a;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2F0743, #41295a);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2F0743, #41295a); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  .aside {
    background: unset;
    border-right: unset;
  }

  #kt_content {
    background: var(--bs-app-bg-color);
  }
}
