.inv-header-course {
  background-color: #564fc1;
  background-image: url("https://via.placeholder.com/1920x1080?text=Sem+Imagem");
  background-position: center center;
  background-size: cover;
  min-height: 300px;
  margin-bottom: 25px;
  position: relative;

  &__content {
    z-index: 1;
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: auto;
    padding: 40px 0;
    top: 0px;
    left: 0px;
    background-color: rgba(117, 59, 189, 0.8);
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    margin: 0;
  }

  &__desc {
    font-size: 1rem;
    font-weight: 500;
    color: rgba(255, 255, 255, .7);
  }

  .inv-header__bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    .inv-widget__item {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 2rem 1.5rem 0 0;
    }
    .inv-widget__icon {
      i {
        font-size: 2.7rem;
        color: #fff;
        font-weight: 400;
      }
    }
    .inv-widget__details {
      padding-left: 1rem;
    }
    .inv-widget__title {
      display: block;
      color: #fff;
      font-weight: 600;
      font-size: 0.95rem;
    }
    .inv-widget__value {
      display: block;
      color: #fff;
      font-weight: 600;
      font-size: 1.2rem;
      span {
        color: #fff;
        font-weight: 400;
      }
    }
  }

  .inv-header__actions {
    margin-top: 2rem;
  }
}

.inv-course-item {
  @extend .bgi-no-repeat;
  @extend .bgi-size-cover;
  @extend .card-xl-stretch;
  @extend .mb-5;
  @extend .mb-xl-8;
  background-image: url("https://images.unsplash.com/photo-1542661206-03e9001692de?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80");
  background-position: center center;
  background-size: cover;
  min-height: 11rem;
}

.inv-module-structure {
  .module-header {
    flex-grow: 1;
    padding-right: 1rem;
  }

  .inv-course {
    &-topics {
      padding-top: 0;
      padding-bottom: 0;

      .timeline-item {
        padding: 1rem 0;
        margin-bottom: 0.5rem;
      }
    }
  }
}

.topic-fractions {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .topic-fragment {
    position: relative;

    &.is-admin {
      border: transparent solid 1px;
      transition: 0.3s;

      .fragment-options {
        z-index: 1;
        position: absolute;
        display: none;
        background-color: var(--bs-primary);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        top: 0;
        left: 50%;
        height: 36px;
        padding: 0 0.5rem;
        transform: translate(-50%);
        color: white;
      }

      &:hover {
        border: var(--bs-primary) solid 1px;

        .fragment-options {
          display: flex;
        }
      }
    }
  }
}
