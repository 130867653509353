.hyk-member-area {
  .hyk-section {
    .hyk-course {
      &-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        grid-auto-rows: 1fr;
        gap: 40px;

        &.wide-portrait {
          .hyk-course-item {
            --bs-aspect-ratio: 177.77%
          }
        }
      }
    }
  }
}
