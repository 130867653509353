:root {
  --hyk-btn-size: 2.65em;
}

.hyk-btn {
  --transition: 300ms ease;

  align-items: center;
  background-color: var(--hyk-btn-background, transparent);
  border-radius: var(--hyk-btn-border-radius, 2em);
  border: var(--hyk-btn-border-size, 1px) solid var(--hyk-btn-border-color, var(--color-subtle-dark));
  color: var(--hyk-btn-color, var(--color-ink));
  cursor: pointer;
  display: inline-flex;
  font-size: 1em;
  font-weight: 600;
  gap: var(--hyk-btn-gap, 0.5em);
  justify-content: center;
  padding: var(--hyk-btn-padding, 0.5em 1.1em);
  pointer-events: auto;
  transition:
    background-color var( --transition),
    border var( --transition),
    color var( --transition),
    filter var( --transition),
    opacity var( --transition);

  img {
    -webkit-touch-callout: none;

    user-select: none;
  }

  /* Default icon styles */
  &:where(:has(img, svg)) {
    text-align: start;

    img, svg {
      block-size: var(--hyk-btn-icon-size, 1.3em);
      inline-size: var(--hyk-btn-icon-size, 1.3em);
      max-inline-size: unset;
    }

    img:not([class]) {
      filter: invert(0);

      @media (prefers-color-scheme: dark) {
        filter: invert(1);
      }
    }
  }

  /* Circle buttons */
  &.hyk-btn--circle,
  &:where(:has(.for-screen-reader):has(img, svg)) {
    --hyk-btn-border-radius: 50%;
    --hyk-btn-padding: 0;

    aspect-ratio: 1;
    block-size: var(--hyk-btn-size);
    display: grid;
    inline-size: var(--hyk-btn-size);
    place-items: center;

    > * {
      grid-area: 1/1;
    }
  }

  /* With radios and checkboxes */
  &:has(input[type=radio], input[type=checkbox]) {
    :is(input[type=radio], input[type=checkbox]) {
      appearance: none;
      block-size: calc(var(--hyk-btn-size) - var(--outline-size));
      border-radius: var(--hyk-btn-border-radius);
      cursor: pointer;
      display: flex;
      inline-size: calc(var(--hyk-btn-size) - var(--outline-size));
      margin: 0;
      padding: 0;
    }

    img.checked {
      display: none;
    }
  }

  &:has(input:checked)  {
    --hyk-btn-background: var(--color-ink);
    --hyk-btn-color: var(--color-ink-reversed);
    --outline-color: var(--color-ink);

    img {
      filter: invert(1);
    }

    @media (prefers-color-scheme: dark) {
      img {
        filter: invert(0);
      }
    }

    img.checked {
      display: block;
    }
  }

  &[disabled],
  &:has([disabled]),
  [disabled] &[type=submit],
  &[type=submit]:disabled {
    cursor: not-allowed;
    opacity: 0.3;
    pointer-events: none;
  }

  @media print {
    display: none;
  }
}

/* Variants */
.hyk-btn--link {
  --hyk-btn-background: var(--color-link);
  --hyk-btn-color: var(--color-ink-reversed);
  --outline-color: var(--color-link);
}

.hyk-btn--negative {
  --hyk-btn-background: var(--color-negative);
  --hyk-btn-color: var(--color-ink-reversed);
  --outline-color: var(--color-negative);
}

.hyk-btn--placeholder {
  pointer-events: none;
  visibility: hidden;
}

.hyk-btn--plain {
  --hyk-btn-border-radius: 0.5em;
  --hyk-btn-border-size: 0;
  --hyk-btn-icon-size: 100%;
  --hyk-btn-padding: 0;
  --hover-size: 0;
}

.hyk-btn--positive {
  --hyk-btn-background: var(--color-positive);
  --hyk-btn-color: var(--color-ink-reversed);
  --outline-color: var(--color-positive);
}

.hyk-btn--reversed {
  --hyk-btn-background: var(--color-ink);
  --hyk-btn-color: var(--color-bg);
  --outline-color: var(--color-ink);
}

:is(.hyk-btn--link, .hyk-btn--negative, .hyk-btn--positive, .hyk-btn--reversed) {
  --hyk-btn-border-color: var(--color-bg);

  img:not([class]) {
    filter: invert(1);

    @media (prefers-color-scheme: dark) {
      filter: invert(0);
    }
  }
}

.hyk-btn--small {
  font-size: 0.8em;
}

.hyk-btn--success {
  animation: success 1s ease-out;

  img {
    animation: zoom-fade 300ms ease-out;
  }
}
