.remote-form {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .modal-body {
    flex: 1 1 auto;
  }
}

.modal-fullscreen.hyk-ebook {
  .modal-body {
    padding: 0;
  }
}
