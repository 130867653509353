.inv-scorm-modal {
  .modal-body {
    overflow-y: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;

    .scorm-iframe {
      flex-grow: 1;
    }
  }
}
